(function () {
  'use strict';

  /* @ngdoc object
   * @name home.ajuntaments
   * @description
   *
   */
  angular
  .module('neo.home.jvp', [
    'ui.router'
  ]);
}());

